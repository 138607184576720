import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const APIKEY = process.env.REACT_APP_APIKEY;

const firebaseConfig = {
  apiKey: "AIzaSyASrCwf9i57H-WBJ2sOyvJydjsgib-6be8",
  authDomain: "anna-planner.firebaseapp.com",
  projectId: "anna-planner",
  storageBucket: "anna-planner.appspot.com",
  messagingSenderId: "210428227860",
  appId: "1:210428227860:web:0fc731a0424ee47795d201",
  measurementId: "G-N1856R9DNW",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

// firebase.firestore().enablePersistence()

export { firebase, db };
